.container {
    .mainContentCoupon {
        padding-top: 20px;
        display: block;
        .filter {
            display: flex;
            width: 100%;
            height: 44.19px;
            gap: 20px;
            .filter-left {
                width: calc(100% - 138px);
                display: flex;
                gap: 100px;
                h1 {
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 24.2px;
                    letter-spacing: -0.02em;
                    text-align: left;
                    margin-bottom: 0px;
                    padding: 10px 0px;
                }
                .filter-search {
                    width: 440px;
                    border-radius: 8px;
                    background: #fff;
                    display: flex;
                    align-items: center;
                    padding: 4px;
                    padding-right: 2px;
                    input {
                        width: calc(100% - 36px);
                        border: unset;
                        border: unset;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 19.6px;
                        letter-spacing: -0.2800000011920929px;
                        text-align: left;
                        color: #061020;
                        &:focus {
                            outline: unset;
                        }
                    }
                    button {
                        width: 36px;
                        background: #0e72ed;
                        height: 36px;
                        border-radius: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: unset;
                    }
                }
                @media screen and (max-width: 850px) {
                    gap: 0;
                    justify-content: space-between;
                    .filter-search {
                        width: 400px;
                    }
                }
            }

            .btnCreate {
                border-radius: 8px;
                width: 130px;
                text-align: center;
                background: #0e72ed;
                border: unset;
                span {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16.94px;
                    letter-spacing: -0.2800000011920929px;
                    text-align: left;
                    color: #ffffff;
                    position: relative;
                    padding-left: 25px;
                    img {
                        top: -3px;
                        left: 0px;
                        position: absolute;
                    }
                }
            }
        }

        .content-table {
            margin-top: 20px;
            .table-responsive {
                thead {
                    width: 100%;
                    display: inline-block;
                    tr {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        th {
                            padding: 12px 10px;
                            white-space: nowrap;
                            align-self: center;
                            &:first-child {
                                width: 10%;
                            }
                            &:last-child {
                                width: 12%;
                            }
                            &:not(:first-child):not(:last-child) {
                                width: 9%;
                            }
                        }
                    }
                }

                tbody {
                    width: 100%;
                    display: inline-block;
                    tr {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        border-bottom: 1px solid #ecf2f7;
                        td {
                            border-bottom: none;
                            &:first-child {
                                width: 10%;
                            }
                            &:last-child {
                                width: 12%;
                                text-align: center;
                            }
                            &:not(:first-child):not(:last-child) {
                                width: 9%;
                            }
                            &:nth-child(3) {
                                text-align: center;
                            }
                            &:nth-child(6) {
                                text-align: center;
                            }
                            img {
                                width: 60px;
                                height: 60px;
                                object-fit: cover;
                            }
                            span {
                                font-size: 14px;
                                display: inline-block;
                                white-space: nowrap;
                            }
                            .status {
                                padding: 3px 10px;
                                border-radius: 12px;
                                color: white;
                                font-size: 12px;
                                background-color: rgb(136, 136, 136);
                            }
                            .status-active {
                                background-color: #58c35a;
                            }
                        }
                    }
                }

                @media screen and (max-width: 775px) {
                    overflow-x: auto;
                }
            }
        }
    }
}

.item-input {
    input {
        &::placeholder {
            text-transform: none;
        }
    }
}

.item-selected {
    .ant-select-selector {
        cursor: pointer !important;
        input {
            pointer-events: none !important;
            caret-color: transparent !important;
        }
    }
}
.modalCreateCoupon,.modalUpdateCoupon{
    .ant-input-number-input{
        padding: 0 !important;
    }
}