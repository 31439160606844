.payment-debt-page .session1 {
  display: flex;
  background: #FFFFFF;
  padding: 14px 32px 14px 32px;
  gap: 20px;
  align-items: center;
  position: relative;
}
.payment-debt-page .session1 h1 {
  font-size: 28px;
  font-weight: 600;
  line-height: 33.89px;
  letter-spacing: -1.4400000572px;
  text-align: center;
  color: #061020;
  margin-bottom: 0px;
  line-height: 100%;
}
.payment-debt-page .session1 .menupos {
  position: absolute;
  top: 50%;
  right: 38px;
  transform: translate(0, -50%);
}
.payment-debt-page .session1 .menupos .contentMenupos {
  position: relative;
}
.payment-debt-page .session1 .menupos .contentMenupos button {
  line-height: 100%;
  padding: 0px;
  border: unset;
  background: unset;
  box-shadow: unset;
}
.payment-debt-page .session1 .menupos .contentMenupos button:focus {
  background-color: unset !important;
}
.payment-debt-page .session1 .menupos .contentMenupos button:hover {
  background-color: unset !important;
}
.payment-debt-page .session1 .menupos .contentMenupos button:after {
  content: unset;
}
.payment-debt-page .session1 .menupos .contentMenupos button i {
  font-size: 25px;
  color: #000000;
}
.payment-debt-page .session1 .menupos .contentMenupos .dropdown-menu {
  top: -23px !important;
  left: -200px !important;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3215686275);
  padding: 24px 20px 32px 20px;
  gap: 16px;
  border-radius: 12px;
  width: 185px;
  transform: unset !important;
}
.payment-debt-page .session1 .menupos .contentMenupos .dropdown-menu a {
  padding: 0px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  letter-spacing: -0.0299999993px;
  text-align: left;
  color: #061020;
  display: block;
  padding-bottom: 16px;
  border-bottom: 1px solid #ECF2F7;
  margin-bottom: 16px;
}
.payment-debt-page .session1 .menupos .contentMenupos .dropdown-menu a:hover {
  background: unset;
}
.payment-debt-page .session1 .menupos .contentMenupos .dropdown-menu a:last-child {
  padding-bottom: 0px;
  border-bottom: 0px;
  margin-bottom: 0px;
}
.payment-debt-page .session2 {
  padding: 8px 20px;
  display: flex;
}
.payment-debt-page .session2 a {
  padding: 12px 10px;
  background: #FFFFFF;
  border: 1px solid #ECF2F7;
  border-radius: 8px;
  text-decoration: unset;
}
.payment-debt-page .session2 a span {
  position: relative;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  letter-spacing: -0.2099999934px;
  text-align: left;
  color: #061020;
  padding-left: 20px;
}
.payment-debt-page .session2 a span img {
  position: absolute;
  top: 4px;
  left: 0px;
}
.payment-debt-page .session2 p {
  display: grid;
  align-items: center;
  width: calc(100% - 186px);
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #061020;
  margin-bottom: 0px;
}
.payment-debt-page .session3 {
  display: flex;
}
.payment-debt-page .session3 .leftSession3 {
  width: 300px;
}
.payment-debt-page .session3 .leftSession3 .content {
  background: #fff;
}
.payment-debt-page .session3 .leftSession3 .content ul {
  padding-left: 0px;
  list-style-type: none;
}
.payment-debt-page .session3 .leftSession3 .content ul li {
  list-style-type: none;
  border-bottom: 2px solid #ECF2F7;
}
.payment-debt-page .session3 .leftSession3 .content ul li button {
  padding: 16px;
  height: auto !important;
  line-height: 130%;
  display: block;
  text-decoration: unset;
}
.payment-debt-page .session3 .leftSession3 .content ul li button span {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.2800000012px;
  text-align: left;
  color: #061020;
  position: relative;
  padding-left: 28px;
  display: block;
}
.payment-debt-page .session3 .leftSession3 .content ul li button span img {
  position: absolute;
  top: 0px;
  left: 0px;
}
.payment-debt-page .session3 .leftSession3 .content ul li button span p {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.2800000012px;
  text-align: left;
  color: #061020;
  position: relative;
  display: block;
  margin-bottom: 0px;
}
.payment-debt-page .session3 .leftSession3 .content ul li .btnModalCustomer {
  padding: 16px;
  height: auto !important;
  line-height: 130%;
  display: block;
  text-decoration: unset;
}
.payment-debt-page .session3 .leftSession3 .content ul li .btnModalCustomer span {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.2800000012px;
  text-align: left;
  color: #061020;
  position: relative;
  padding-left: 28px;
  display: block;
}
.payment-debt-page .session3 .leftSession3 .content ul li .btnModalCustomer span img {
  position: absolute;
  top: 0px;
  left: 0px;
}
.payment-debt-page .session3 .leftSession3 .content ul li .btnModalCustomer span p {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.2800000012px;
  text-align: left;
  color: #061020;
  position: relative;
  display: block;
  margin-bottom: 0px;
}
.payment-debt-page .session3 .leftSession3 .content ul li .btnModalCustomer {
  background: unset;
  border: unset !important;
  box-shadow: unset;
}
.payment-debt-page .session3 .leftSession3 .content ul li .btnModalCustomer:hover {
  background: unset !important;
}
.payment-debt-page .session3 .leftSession3 .content ul li .btnModalCustomer:focus {
  background: unset !important;
}
.payment-debt-page .session3 .leftSession3 .content ul li .btnNotePay {
  background: unset;
  border: unset !important;
  box-shadow: unset;
}
.payment-debt-page .session3 .leftSession3 .content ul li .btnNotePay:hover {
  background: unset !important;
}
.payment-debt-page .session3 .leftSession3 .content ul li .btnNotePay:focus {
  background: unset !important;
}
.payment-debt-page .session3 .leftSession3 .content ul li .btnNotePay p {
  margin-top: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.2800000012px;
  text-align: left;
  color: #6D727C;
  margin-bottom: 0px;
}
.payment-debt-page .session3 .leftSession3 .content ul li .btnNotePay .hidden {
  display: none;
}
.payment-debt-page .session3 .leftSession3 .content ul li .imageBank {
  padding: 16px 16px 8px;
  height: auto !important;
  line-height: 130%;
  display: block;
  text-decoration: unset;
  margin-bottom: 0px;
}
.payment-debt-page .session3 .leftSession3 .content ul li .imageBank span {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.2800000012px;
  text-align: left;
  color: #061020;
  position: relative;
  display: block;
}
.payment-debt-page .session3 .leftSession3 .content ul li .upload-form {
  padding-bottom: 16px;
  width: 90%;
  margin: auto;
}
.payment-debt-page .session3 .leftSession3 .content ul li .upload-form .upload {
  height: 116px !important;
}
.payment-debt-page .session3 .leftSession3 .content ul li .upload-form .upload a {
  height: 90px !important;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.payment-debt-page .session3 .leftSession3 .content ul li .upload-form .upload a img {
  max-height: 100%;
  max-width: 100%;
}
.payment-debt-page .session3 .leftSession3 .content ul li .hidden {
  display: none;
}
.payment-debt-page .session3 .centerSession3 {
  width: calc(100% - 600px);
}
.payment-debt-page .session3 .centerSession3 .contentFull {
  border: 2px solid #BABABA;
}
.payment-debt-page .session3 .centerSession3 .contentFull .content1 {
  height: 120px;
  background: #fff;
  display: grid;
  align-items: center;
  border-bottom: 2px solid #BABABA;
}
.payment-debt-page .session3 .centerSession3 .contentFull .content1 .nd .priceTotal {
  font-size: 48px;
  font-weight: 500;
  line-height: 55.2px;
  letter-spacing: -1.4400000572px;
  text-align: center;
  color: #088C3D;
  margin-bottom: 0px;
}
.payment-debt-page .session3 .centerSession3 .contentFull .content1 .nd .notePay {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  letter-spacing: -0.0299999993px;
  text-align: center;
  color: #061020;
}
.payment-debt-page .session3 .centerSession3 .contentFull .content1 .nd2 {
  padding: 32px 20px;
  display: flex;
}
.payment-debt-page .session3 .centerSession3 .contentFull .content1 .nd2 .leftNd {
  width: 50%;
}
.payment-debt-page .session3 .centerSession3 .contentFull .content1 .nd2 .leftNd .text1 {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #061020;
  margin-bottom: 8px;
}
.payment-debt-page .session3 .centerSession3 .contentFull .content1 .nd2 .leftNd .text2 {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.0320000015px;
  text-align: left;
  color: #061020;
}
.payment-debt-page .session3 .centerSession3 .contentFull .content1 .nd2 .rightNd {
  width: 50%;
}
.payment-debt-page .session3 .centerSession3 .contentFull .content1 .nd2 .rightNd .text3 {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  letter-spacing: -0.02em;
  text-align: right;
  color: #061020;
}
.payment-debt-page .session3 .centerSession3 .contentFull .content1 .hidden {
  display: none;
}
.payment-debt-page .session3 .centerSession3 .contentFull .content2 {
  height: calc(100vh - 306.19px);
  background: #ECF2F7;
}
.payment-debt-page .session3 .centerSession3 .contentFull .content2 .listNumber {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2px;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.payment-debt-page .session3 .centerSession3 .contentFull .content2 .listNumber button {
  border: unset;
  display: grid;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 400;
  line-height: 29.05px;
  letter-spacing: -1.4400000572px;
  text-align: left;
  color: #061020;
  height: 100%;
  background: #fff;
  text-decoration: unset;
}
.payment-debt-page .session3 .centerSession3 .contentFull .content2 .listNumber .payClick {
  background: #0e72ed;
  color: #fff;
}
.payment-debt-page .session3 .rightSession3 {
  width: 300px;
}
.payment-debt-page .session3 .rightSession3 .content {
  background: #fff;
}
.payment-debt-page .session3 .rightSession3 .content ul {
  padding-left: 0px;
  list-style-type: none;
  margin-bottom: 0px;
}
.payment-debt-page .session3 .rightSession3 .content ul li {
  list-style-type: none;
  border-bottom: 2px solid #ECF2F7;
}
.payment-debt-page .session3 .rightSession3 .content ul li p {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  letter-spacing: -0.2099999934px;
  text-align: left;
  color: #061020;
  padding: 16px 16px;
}
.payment-debt-page .session3 .rightSession3 .content ul li button {
  border: unset;
  width: 100%;
  background: unset;
  height: auto;
  padding: 16px;
  height: auto !important;
  line-height: 130%;
  display: block;
  text-decoration: unset;
}
.payment-debt-page .session3 .rightSession3 .content ul li button span {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.2800000012px;
  text-align: left;
  color: #061020;
  position: relative;
  padding-left: 28px;
  display: block;
}
.payment-debt-page .session3 .rightSession3 .content ul li button span img {
  position: absolute;
  top: 0px;
  left: 0px;
}
.payment-debt-page .session3 .rightSession3 .content ul li .summary {
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  letter-spacing: -0.2099999934px;
  text-align: left;
  color: #061020;
  padding-left: 0px;
}
.payment-debt-page .session3 .rightSession3 .content #contentPayType {
  height: calc(100vh - 400.57px);
  overflow: auto;
  display: block;
  gap: 2px;
}
.payment-debt-page .session3 .rightSession3 .content #contentPayType .item {
  display: flex;
  padding: 28px 20px;
  align-items: center;
  background: #fff;
  border-bottom: 2px solid #ECF2F7;
}
.payment-debt-page .session3 .rightSession3 .content #contentPayType .item p {
  margin-bottom: 0px;
}
.payment-debt-page .session3 .rightSession3 .content #contentPayType .item .text {
  width: 90px;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.2800000012px;
  text-align: left;
  color: #061020;
  display: grid;
  height: 100%;
  align-items: center;
}
.payment-debt-page .session3 .rightSession3 .content #contentPayType .item .price {
  height: 100%;
  width: calc(100% - 138px);
  color: #061020;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.2800000012px;
  text-align: right;
  display: grid;
  align-items: center;
}
.payment-debt-page .session3 .rightSession3 .content #contentPayType .item .delete {
  background: unset;
  border: unset;
  width: 48px;
  text-align: right;
}
.payment-debt-page .session3 .rightSession3 .content #contentPayType .itemActive {
  background: #ECF2F7;
}
.payment-debt-page #notePay {
  height: 100vh;
  overflow: hidden;
}
.payment-debt-page #notePay .modal-dialog {
  height: 100vh;
  overflow: hidden;
  width: 800px;
  max-width: 800px;
  padding-top: 1rem;
}
.payment-debt-page #notePay .modal-dialog .modal-content {
  border-radius: 20px;
}
.payment-debt-page #notePay .modal-dialog .modal-content .modal-body {
  position: relative;
  padding: 0px;
}
.payment-debt-page #notePay .modal-dialog .modal-content .modal-body .close {
  position: relative;
  top: 34px;
  right: 34px;
  opacity: 1;
}
.payment-debt-page #notePay .modal-dialog .modal-content .modal-body .close:focus {
  outline: unset;
}
.payment-debt-page #notePay .modal-dialog .modal-content .modal-body .contentModal {
  padding: 32px 40px;
}
.payment-debt-page #notePay .modal-dialog .modal-content .modal-body .contentModal .headerModel {
  grid-template-columns: 1fr;
  gap: 20px;
  display: grid;
  border-bottom: 1px solid #ECF2F7;
  padding-bottom: 24px;
  margin-bottom: 24px;
}
.payment-debt-page #notePay .modal-dialog .modal-content .modal-body .contentModal .headerModel p {
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
  letter-spacing: -1.4400000572px;
  text-align: left;
  color: #000000;
  margin-bottom: 0px;
}
.payment-debt-page #notePay .modal-dialog .modal-content .modal-body .contentModal .contentCustomer {
  display: grid;
  gap: 24px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #ECF2F7;
}
.payment-debt-page #notePay .modal-dialog .modal-content .modal-body .contentModal .contentCustomer .inlineForm {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}
.payment-debt-page #notePay .modal-dialog .modal-content .modal-body .contentModal .contentCustomer .form-customer {
  position: relative;
}
.payment-debt-page #notePay .modal-dialog .modal-content .modal-body .contentModal .contentCustomer .form-customer .error {
  position: absolute;
  bottom: -22px;
  left: 0px;
  color: #FF0000;
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  letter-spacing: -0.2800000012px;
  text-align: left;
}
.payment-debt-page #notePay .modal-dialog .modal-content .modal-body .contentModal .contentCustomer .form-customer .hidden {
  display: none;
}
.payment-debt-page #notePay .modal-dialog .modal-content .modal-body .contentModal .contentCustomer .form-customer label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.2800000012px;
  text-align: left;
  color: #061020;
}
.payment-debt-page #notePay .modal-dialog .modal-content .modal-body .contentModal .contentCustomer .form-customer label span {
  color: #FF0000;
}
.payment-debt-page #notePay .modal-dialog .modal-content .modal-body .contentModal .contentCustomer .form-customer textarea {
  border: 1px solid #ECF2F7;
  background: #F6F9FC;
  height: 200px;
  border-radius: 8px;
  padding: 12px;
  resize: unset;
  width: 100%;
}
.payment-debt-page #notePay .modal-dialog .modal-content .modal-body .contentModal .contentCustomer .form-customer textarea:focus {
  outline: unset;
}
.payment-debt-page #notePay .modal-dialog .modal-content .modal-body .contentModal .footerCustomer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}
.payment-debt-page #notePay .modal-dialog .modal-content .modal-body .contentModal .footerCustomer .btnCloseNote {
  text-decoration: unset;
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  letter-spacing: -0.0299999993px;
  text-align: left;
  color: #FFFFFF;
  background: #BABABA;
  border-radius: 100px;
}
.payment-debt-page #notePay .modal-dialog .modal-content .modal-body .contentModal .footerCustomer .btnAddNotePay {
  text-decoration: unset;
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  letter-spacing: -0.0299999993px;
  text-align: left;
  color: #FFFFFF;
  background: #0e72ed;
  border-radius: 100px;
}
.payment-debt-page #vietQr {
  height: 100vh;
  overflow: hidden;
}
.payment-debt-page #vietQr .modal-dialog {
  height: 100vh;
  overflow: hidden;
  width: 800px;
  max-width: 800px;
  padding-top: 1rem;
}
.payment-debt-page #vietQr .modal-dialog .modal-content {
  border-radius: 20px;
}
.payment-debt-page #vietQr .modal-dialog .modal-content .modal-body {
  position: relative;
  padding: 0px;
}
.payment-debt-page #vietQr .modal-dialog .modal-content .modal-body .close {
  position: relative;
  top: 34px;
  right: 34px;
  opacity: 1;
}
.payment-debt-page #vietQr .modal-dialog .modal-content .modal-body .close:focus {
  outline: unset;
}
.payment-debt-page #vietQr .modal-dialog .modal-content .modal-body .contentModal {
  padding: 32px 40px;
}
.payment-debt-page #vietQr .modal-dialog .modal-content .modal-body .contentModal .headerModel {
  grid-template-columns: 1fr;
  gap: 20px;
  display: grid;
  border-bottom: 1px solid #ECF2F7;
  padding-bottom: 24px;
  margin-bottom: 24px;
}
.payment-debt-page #vietQr .modal-dialog .modal-content .modal-body .contentModal .headerModel p {
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
  letter-spacing: -1.4400000572px;
  text-align: left;
  color: #000000;
  margin-bottom: 0px;
}
.payment-debt-page #vietQr .modal-dialog .modal-content .modal-body .contentModal .contentVietAr {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #ECF2F7;
  height: 400px;
}
.payment-debt-page #vietQr .modal-dialog .modal-content .modal-body .contentModal .contentVietAr img {
  max-height: 100%;
  max-width: 100%;
}
.payment-debt-page #vietQr .modal-dialog .modal-content .modal-body .contentModal .footerCustomer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}
.payment-debt-page #vietQr .modal-dialog .modal-content .modal-body .contentModal .footerCustomer .btnCloseVietQr {
  text-decoration: unset;
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  letter-spacing: -0.0299999993px;
  text-align: left;
  color: #FFFFFF;
  background: #BABABA;
  border-radius: 100px;
}
.payment-debt-page #vietQr .modal-dialog .modal-content .modal-body .contentModal .footerCustomer .btnSave {
  text-decoration: unset;
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  letter-spacing: -0.0299999993px;
  text-align: left;
  color: #FFFFFF;
  background: #0e72ed;
  border-radius: 100px;
}
.payment-debt-page .modalNote .ant-modal-content {
  padding: 32px 40px;
  border-radius: 20px;
}
.payment-debt-page .modalNote .ant-modal-content .ant-modal-close {
  top: 33px;
  right: 42px;
}
.payment-debt-page .modalNote .ant-modal-content .ant-modal-close .ant-modal-close-x {
  font-size: 26px;
}
.payment-debt-page .modalNote .ant-modal-content .ant-modal-close .ant-modal-close-x svg {
  width: 26px;
}
.payment-debt-page .modalNote .ant-modal-content .ant-modal-close .ant-modal-close-x svg path {
  fill: #000;
}
.payment-debt-page .modalNote .ant-modal-content .ant-modal-header {
  border-bottom: 1px solid #ECF2F7;
  padding-bottom: 24px;
  margin-bottom: 24px;
}
.payment-debt-page .modalNote .ant-modal-content .ant-modal-header .ant-modal-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 29.05px;
  letter-spacing: -1.4400000572px;
  text-align: left;
  color: #000000;
  padding: 0px;
  text-transform: unset !important;
}
.payment-debt-page .modalNote .ant-modal-content .ant-modal-body .item-form {
  border: unset;
  padding: 0px;
}
.payment-debt-page .modalNote .ant-modal-content .ant-modal-body .form-modal {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #ECF2F7;
}
.payment-debt-page .modalNote .ant-modal-content .ant-modal-body .form-modal textarea {
  border: 1px solid #ECF2F7;
  background: #F6F9FC;
  height: 200px;
  border-radius: 8px;
  padding: 12px;
  resize: unset;
  width: 100%;
}
.payment-debt-page .modalNote .ant-modal-content .ant-modal-body .form-modal-image {
  width: 250px;
  margin: 0px auto 24px;
}
.payment-debt-page .modalNote .ant-modal-content .ant-modal-body .form-modal-image img {
  max-width: 100%;
}
.payment-debt-page .modalNote .ant-modal-content .ant-modal-body .footer-note {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}
.payment-debt-page .modalNote .ant-modal-content .ant-modal-body .footer-note .btnCloseNote {
  text-decoration: unset;
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  letter-spacing: -0.0299999993px;
  text-align: left;
  color: #FFFFFF;
  background: #BABABA;
  border-radius: 100px;
}
.payment-debt-page .modalNote .ant-modal-content .ant-modal-body .footer-note .btnAddNotePay {
  text-decoration: unset;
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  letter-spacing: -0.0299999993px;
  text-align: left;
  color: #FFFFFF;
  background: #0e72ed;
  border-radius: 100px;
}

@media screen and (max-width: 992px) {
  .payment-debt-page .session3 .leftSession3 {
    width: 200px;
    background: #fff;
  }
  .payment-debt-page .session3 .leftSession3 .content ul li .btnModalCustomer {
    width: 100%;
  }
  .payment-debt-page .session3 .rightSession3 {
    width: 200px;
  }
  .payment-debt-page .session3 .rightSession3 .content ul li p {
    padding: 16px 5px;
  }
  .payment-debt-page .session3 .rightSession3 .content #contentPayType .item {
    padding: 28px 5px;
  }
  .payment-debt-page .session3 .rightSession3 .content #contentPayType .item .text {
    font-size: 12px;
    width: 80px;
    display: none;
  }
  .payment-debt-page .session3 .rightSession3 .content #contentPayType .item .text-mobile {
    display: block;
    width: 32px;
  }
  .payment-debt-page .session3 .rightSession3 .content #contentPayType .item .delete {
    width: 38px;
  }
  .payment-debt-page .session3 .rightSession3 .content #contentPayType .item .price {
    width: calc(100% - 70px);
    font-size: 12px;
    overflow: hidden;
  }
  .payment-debt-page .session3 .centerSession3 {
    width: calc(100% - 400px);
  }
  .payment-debt-page .session3 .centerSession3 .contentFull .content1 .nd .priceTotal {
    font-size: 32px;
  }
  .payment-debt-page .session3 .centerSession3 .contentFull .content1 .nd .notePay {
    font-size: 14px;
  }
  .payment-debt-page .session3 .centerSession3 .contentFull .content1 .nd2 {
    padding: 0px 10px;
    display: grid;
  }
  .payment-debt-page .session3 .centerSession3 .contentFull .content1 .nd2 .leftNd {
    width: 100%;
  }
  .payment-debt-page .session3 .centerSession3 .contentFull .content1 .nd2 .leftNd .text1 {
    font-size: 14px;
    margin-bottom: 0px;
  }
  .payment-debt-page .session3 .centerSession3 .contentFull .content1 .nd2 .rightNd {
    width: 100%;
  }
  .payment-debt-page .session3 .centerSession3 .contentFull .content1 .nd2 .rightNd .text3 {
    font-size: 14px;
    text-align: left;
  }
  .payment-debt-page .session3 .centerSession3 .contentFull .content2 .listNumber button {
    font-size: 18px;
  }
}

