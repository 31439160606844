.ModalDetailProduct{
    .ant-modal-content{
        padding: 20px 24px;
    }
    .loadingPreviewProduct{
        height: 490px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &-container{
       display: flex;
       align-items: start;
        column-gap: 20px;
        .boxImage{
            width: 300px;
            display: flex;
            flex-direction: column;
            row-gap: 8px;
            .big-Image{
                display: flex !important;
                height: 377px;
                align-items: center;
                justify-content: center;
                border-radius: 12px;
                background: #D9D9D9;
                img{
                    max-width: 100%;
                    max-height: 100%;
                    border-radius: 12px;
                }
            }
            &-thumbnail{
                .thumnail-Image{
                    width: 68.71px !important;
                    height: 68.71px !important;
                    border-radius: 8px;
                    border: 1px solid #dddddd;
                    display: flex !important;
                    align-items: center;
                    justify-content: center;
                    background: #D9D9D9;
                    outline: none;
                    img{
                        border-radius: 8px;
                        max-height: 100%;
                        max-width: 100%;
                        cursor: pointer;
                    }
                }

                .slick-slide > div{
                    margin-right: 8px;
                }
                .boxImage-minthumbnail{
                    display: flex;
                    align-items: center;
                    column-gap: 8px;
                }
                .imgless4{
                    .slick-slide{
                        width: 75px !important;
                    }
                }
            }
        }
        .productInfor{
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            width: 100%;
            h4{
                font-size: 18px;
                font-weight: 600;
                line-height: 21.78px;
                text-align: left;
                color: #061020;
                margin-bottom: 0px;
                padding: 0px;
                margin-top: 0px;
                &.multiline-ellipsis {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2; /* start showing ellipsis when 2rd line is reached */
                    white-space: pre-wrap; /* let the text wrap preserving spaces */
                  }
            }
            &-price{
                span{
                    display: block;
                    text-decoration-line: line-through;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 24.2px;
                    letter-spacing: -0.02em;
                    text-align: left;
                    color: #6D727C;
                    margin-bottom: 0px;
                }
                h1{
                    font-size: 38px;
                    font-weight: 700;
                    line-height: 43.7px;
                    letter-spacing: -2.2400000095px;
                    text-align: left;
                    margin-bottom: 0px;
                    color: #000000;
                }
            }
            &-quantity{
                display: flex;
                    gap: 8px;
                    span{
                        width: 44px;
                        height: 44px;
                        border-radius: 8px;
                        border: 1px solid #0E72ED;
                        display: grid;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        color: #0E72ED;
                        i{
                            
                        }
                    }
                    input{
                        border: 2px solid #ECF2F7;
                        height: 44px;
                        border-radius: 8px;
                        text-align: center;
                        width: 72px;
                        outline: none;
                    }
            }

            &-variant{
                &__title{
                    h4{
                        margin-bottom: 0px;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: -0.0320000015px;
                        text-align: left;
                        color: #000000;
                    }
                }
                &__body{
                    display: flex;
                    align-items: center;
                    column-gap: 12px;
                    margin-top: 6px;
                    flex-wrap: wrap;
                }
                &__content{
                    background: #ECF2F7;
                    display: inline-block;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 19.6px;
                    letter-spacing: -0.2800000012px;
                    text-align: left;
                    padding: 12px 20px 12px 20px;
                    border-radius: 8px;
                    color: #061020;
                    cursor: pointer;
                    margin-bottom: 12px;
                    text-align: center;
                    &.active{
                        background: #0E72ED;
                        color: #fff;
                    }
                    &:hover{
                        background: #0E72ED;
                        color: #fff;
                    }
                }
            }
            &-addCart{
              
                background: #0E72ED;
                height: 52px;
                display: flex;
                align-items: center;
                justify-content: center;
                column-gap: 10px;
                border-radius: 100px;
                text-decoration: unset;
                cursor: pointer;
                &.disableAddtoCart{
                    pointer-events: none;
                    opacity: 60%;
                }
                p{
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 24.2px;
                    letter-spacing: -0.02em;
                    text-align: left;
                    margin-bottom: 0px;
                    color: #FFFFFF;
                }
                i{
                    font-size: 20px;
                    color: #FFF;
                }
            }
        }
    }
}