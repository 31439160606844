.mainContent{
  padding-bottom: 60px;
  display: flex;
  margin-top: 12px;
  gap: 12px;
  width: 100%;
  .mainContentLeft{
    width: calc(100% - 300px);
    .mainContentLeft-RevenueOrder{
      padding: 20px 16px 20px 16px;
      gap: 24px;
      border-radius: 8px;
      background: url('../../../../public/assets/images/bg_home.webp')no-repeat;
      background-size: cover;
      .mainContentLeftFilter{
        display: flex;
        gap: 20px;
        p{
          margin-bottom: 0px;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: -0.10000000149011612px;
          text-align: left;
          color: #061020;
        }
        .fitter {
          position: relative;
          .dropdown{
            button{
              border-radius: 100px;
              text-decoration: unset;
              font-size: 14px;
              font-weight: 400;
              line-height: 19.6px;
              letter-spacing: -0.2800000011920929px;
              text-align: center;
              padding: 6px 27px 6px 12px;
              background: #FFFFFF;
              position: relative;
              border:0px;
              &:after{
                content: unset;
              }
              &:focus{
                outline: unset;
                box-shadow: unset;
              }
              span{
                font-size: 14px;
                font-weight: 400;
                line-height: 19.6px;
                letter-spacing: -0.2800000011920929px;
                text-align: center;
                color: #0E72ED;
              }
              img{
                position: absolute;
                right: 12px;
                top:50%;
                transform: translate(0%,-50%);
              }
            }
          }
          .dropdown-menu{
            background: #fff;
            z-index: 1;
            width: 200px;
            border-radius: 4px;
            border: 1px solid #ECF2F7;
            box-shadow: 0px 0px 12px 0px #0000001A;
            a{
              border-bottom: 1px solid #ECF2F7;
              background-color: unset;
              display: block;
              width: 100%;
              padding: 12px;
              font-size: 14px;
              font-weight: 500;
              line-height: 16.94px;
              letter-spacing: -0.2800000011920929px;
              text-align: left;
              color: #061020;
              display: block;
            }
          }

        }
      }
      .mainContentLeftRevenueOrder{
        margin-top: 24px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px;

        .mainContentLeftRevenue{
          padding: 20px;
          border-radius: 4px ;
          border-left: 4px solid #FE9339;
          background: #fff;
          display: flex;
          align-items: center;
          gap:12px;
          .image{
            border-radius: 50%;
            width: 52px;
            height: 52px;
            background: #FE9339;
            padding: 10px;
            display: grid;
            align-items: center;
            justify-content: center;
          }
          .price{
            width: calc(100% - 52px);
            .price-value{
              font-size: 38px;
              font-weight: 500;
              line-height: 43.7px;
              letter-spacing: -2.240000009536743px;
              text-align: left;
              color: #061020;
              margin-bottom: 0px;
            }
            .price-text{
              font-size: 14px;
              font-weight: 500;
              line-height: 16.94px;
              letter-spacing: -0.2800000011920929px;
              text-align: left;
              color: #6D727C;
              margin-bottom: 0px;
            }
          }
        }
        .mainContentLeftOrder{
          padding: 20px;
          border-radius: 4px ;
          border-left: 4px solid #FF538A;
          background: #fff;
          display: flex;
          align-items: center;
          gap:12px;
          .image{
            border-radius: 50%;
            width: 52px;
            height: 52px;
            background: #FF538A;
            padding: 10px;
            display: grid;
            align-items: center;
            justify-content: center;
          }
          .listPrice{
            width: calc(100% - 52px);
            display: grid;
            gap: 8px;
            grid-template-columns: repeat(4,1fr);
            .itemPrice{
              .value{
                font-size: 38px;
                font-weight: 500;
                line-height: 43.7px;
                letter-spacing: -2.240000009536743px;
                text-align: left;
                color: #061020;
                margin-bottom: 0px;
              }
              .text{
                font-size: 14px;
                font-weight: 500;
                line-height: 16.94px;
                letter-spacing: -0.2800000011920929px;
                text-align: left;
                color: #6D727C;
                margin-bottom: 0px;
              }
            }

          }
        }
      }
    }
    .sale-customer{
      display: grid;
      // grid-template-columns: 1fr 1fr;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 12px;
    }
    #saleChart{
      margin-top: 12px;
      padding: 20px;
      border-radius: 4px;
      border-top: 4px solid #0E72ED;
      background: #fff;
      .saleChartHeader{
        margin-bottom: 20px;
        display: flex;
        p{
          display: grid;
          align-items: center;
          width: calc(100% - 325px);
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: -0.10000000149011612px;
          text-align: left;
          color: #061020;
          margin-bottom: 0px;
          margin-top: 0px;
        }
        .saleChartHeaderFilter{
          width: 325px;
          .dropdown{
            display: none;
            button{
              border-radius: 100px;
              text-decoration: unset;
              font-size: 14px;
              font-weight: 400;
              line-height: 19.6px;
              letter-spacing: -0.2800000012px;
              text-align: center;
              padding: 6px 27px 6px 12px;
              background: rgba(14, 114, 237, 0.0784313725);
              position: relative;
              border: 0px;
              &:focus{
                box-shadow: unset;
                outline: unset;
              }
              &:after{
                content: unset;
              }
              span{
                font-size: 14px;
                font-weight: 400;
                line-height: 19.6px;
                letter-spacing: -0.2800000012px;
                text-align: center;
                color: #0E72ED;
              }
              img{
                position: absolute;
                right: 12px;
                top: 50%;
                transform: translate(0%, -50%);
              }
            }
            .dropdown-menu{
              background: #fff;
              z-index: 1;
              width: 200px;
              border-radius: 4px;
              border: 1px solid #ECF2F7;
              box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1019607843);
              a{
                border-bottom: 1px solid #ECF2F7;
                background-color: unset;
                display: block;
                width: 100%;
                padding: 12px;
                font-size: 14px;
                font-weight: 500;
                line-height: 16.94px;
                letter-spacing: -0.2800000012px;
                text-align: left;
                color: #061020;
                display: block;
              }
            }
          }
          ul{
            margin-bottom: 0px;
            display: flex;
            justify-content: end;
            gap: 8px;
            list-style-type: none;
            li{
              button{
                background-color: unset;
                border: unset;
                text-decoration: none;
                font-size: 14px;
                font-weight: 500;
                line-height: 16.94px;
                letter-spacing: -0.2800000011920929px;
                text-align: center;
                color: #061020;
                padding: 8px 16px 8px 16px;
                border-radius:100px;
                background: #0E72ED14;
              }
              .active{
                background: #0E72ED;
                color: #fff;
              }
            }
          }
        }

      }
    }
    #customerChart{
      margin-top: 12px;
      padding: 20px;
      border-radius: 4px;
      border-top: 4px solid #0E72ED;
      background: #fff;
      .saleChartHeader{
        margin-bottom: 20px;
        display: flex;
        p{
          margin-top: 0px;
          width: calc(100% - 325px);
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: -0.10000000149011612px;
          text-align: left;
          color: #061020;
          display: grid;
          align-items: center;
          margin-bottom: 0px;
          display: grid;
          align-items: center;
        }
        .saleChartHeaderFilter{
          width: 325px;
          .dropdown{
            display: none;
            button{
              border-radius: 100px;
              text-decoration: unset;
              font-size: 14px;
              font-weight: 400;
              line-height: 19.6px;
              letter-spacing: -0.2800000012px;
              text-align: center;
              padding: 6px 27px 6px 12px;
              background: rgba(14, 114, 237, 0.0784313725);
              position: relative;
              border: 0px;
              &:focus{
                box-shadow: unset;
                outline: unset;
              }
              &:after{
                content: unset;
              }
              span{
                font-size: 14px;
                font-weight: 400;
                line-height: 19.6px;
                letter-spacing: -0.2800000012px;
                text-align: center;
                color: #0E72ED;
              }
              img{
                position: absolute;
                right: 12px;
                top: 50%;
                transform: translate(0%, -50%);
              }
            }
            .dropdown-menu{
              background: #fff;
              z-index: 1;
              width: 200px;
              border-radius: 4px;
              border: 1px solid #ECF2F7;
              box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1019607843);
              a{
                border-bottom: 1px solid #ECF2F7;
                background-color: unset;
                display: block;
                width: 100%;
                padding: 12px;
                font-size: 14px;
                font-weight: 500;
                line-height: 16.94px;
                letter-spacing: -0.2800000012px;
                text-align: left;
                color: #061020;
                display: block;
              }
            }
          }
          ul{
            display: flex;
            justify-content: end;
            gap: 8px;
            list-style-type: none;
            margin-bottom: 0px;
            li{
              button{
                background-color: unset;
                border: unset;
                text-decoration: none;
                font-size: 14px;
                font-weight: 500;
                line-height: 16.94px;
                letter-spacing: -0.2800000011920929px;
                text-align: center;
                color: #061020;
                padding: 8px 16px 8px 16px;
                border-radius:100px;
                background: #0E72ED14;
              }
              .active{
                background: #0E72ED;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
  .mainContentRight{
    width: 300px;
    .mainContentRightNoti{
      border-top: 4px solid #01BFAC;
      border-radius: 4px;
      background: #FFFFFF;
      height: calc(100vh - 105px);
      overflow: auto;
      position: sticky;
      top: 20px;
      width: 100%;
      .mainContentRightNotiTitle{
        padding: 12px 12px;
        margin-bottom: 0px;
        position: relative;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.10000000149011612px;
        text-align: left;
        color: #061020;
        button{
          position: absolute;
          top:50%;
          right: 12px;
          transform: translate(0,-50%);
          background: unset;
          border: unset;
        }
      }
      .mainContentRightNotiList{
        .itemNoti{
          text-decoration: unset;
          background: #F6FAFF;
          border-bottom: 1px solid #F0F2F5;
          display: flex;
          gap:8px;
          padding: 12px 16px;
          .itemNotiIcon{
            background: #D5E7FF;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            display: grid;
            align-items: center;
            justify-content: center;
          }
          .itemNotiText{
            width: calc(100% - 32px);
            .text{
              margin-bottom: 4px;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: -0.03200000151991844px;
              text-align: left;
              color: #000000;
              span{
                font-weight: 500;
              }
            }
            .time{
              margin-bottom: 0px;
              font-size: 14px;
              font-weight: 400;
              line-height: 16.41px;
              text-align: left;
              color: #6F767E;
              padding-left: 20px;
              position: relative;
              img{
                position: absolute;
                top:50%;
                left: 0px;
                transform: translate(0%,-50%);
              }
            }
          }
        }
        .itemNotiWatched{
          background: #fff;
        }
        .more{
          margin-top: 12px;
          padding: 0px 20px 10px;
          button{
            padding: 8px 12px 8px 12px;
            gap: 10px;
            border-radius: 8px;
            background: #F6F9FC;
            display: grid;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 400;
            line-height: 18.75px;
            color: #0E72ED;
            text-decoration: unset;
            width: 100%;
            border: unset;
            &:hover{
              background: #0E72ED;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width:1700px){

  .mainContent .mainContentLeft{
    #saleChart .saleChartHeader {
      p{
        font-size: 14px;
        width: calc(100% - 150px);
      }
      .saleChartHeaderFilter {
        width: 150px;
        display: flex;
        justify-content: end;
        ul{
          display: none;
        }
        .dropdown{
          display: block;
        }
      }
    }
    #customerChart {
      .saleChartHeader {
        p{
          font-size: 14px;
          width: calc(100% - 150px);
        }
        .saleChartHeaderFilter {
          width: 150px;
          display: flex;
          justify-content: end;
          ul{
            display: none;
          }
          .dropdown{
            display: block;
          }
        }
        .saleChartHeaderFilterProduct{
          width: 350px;
          ul{
            display: flex;
          }
        }
      }
      .saleChartHeaderProduct{
        p{
          width: calc(100% - 350px);
        }
      }
    }
  }
}
@media screen and (max-width:1280px){
  .mainContent {
    .mainContentLeft{
      width: 100%;
      .mainContentLeft-RevenueOrder .mainContentLeftRevenueOrder {
        .mainContentLeftRevenue .price {
          .price-value{
            font-size: 32px;
          }
          .price-text{
            font-size: 12px;
          }
        }
        .mainContentLeftOrder {
          .listPrice .itemPrice {
            .value{
              font-size: 32px;
            }
            .text{
              font-size: 12px;
            }
          }
        }
      }
    }
    .mainContentRight{
      display: none;
    }
  }
}
@media screen and (max-width:1140px){
  .mainContent .mainContentLeft .sale-customer{
    grid-template-columns: 1fr;
  }
  .chart canvas{
    width: 100% !important;
  }
}
@media screen and (max-width:992px){
  .mainContent .mainContentLeft .mainContentLeft-RevenueOrder .mainContentLeftRevenueOrder{
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width:500px){
  .mainContent .mainContentLeft .mainContentLeft-RevenueOrder .mainContentLeftFilter{
    display: grid;
  }
}
.ant-modal-wrap{
  .modal-date{
    .ant-modal-content{
      width: 600px;
      padding: 10px 30px 20px;
      margin: auto;
      position: relative;
      .ant-modal-footer {
        margin-top: 30px;
        .ant-btn-default {
          width: 100px;
          height: 40px;
        }
        .ant-btn-primary {
          width: 100px;
          height: 40px;
        }
      }

    }
  }
}