@import "../../../../public/assets/css/variables.scss";

.SalePointContainer {
  padding: 0;

  .mainContentSalePoint {
    .header {
      max-width: 100%;
      background-color: #FFFFFF;
      display: flex;
      gap: 20px;
      align-items: center;
      padding: 14px 38px 14px 38px;

      &__title {
        width: 55px;
        font-size: 28px;
        line-height: 44px;
        font-weight: 600;
      }

      .dropdown {
        //width: 210px;
        background-color: #F6F9FC;
        padding: 12px;
        border-radius: 12px;
        line-height: 20px;
        font-weight: 600;
        font-size: 16px;
        text-align: left;
        color: $primary-color;
        display: flex;
        align-items: center;
        gap: 10px;

        &__location {
          font-size: 16px;
          line-height: 20px;
          font-weight: 600;
          color: $primary-color;
          .ant-select-selector {
            width: 250px;
          }
        }
      }
    }

    .body-page{
      .filter-tab-wrapper {

      }
    }

    .list-item {
      padding: 40px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 32px;
      @media (max-width: 1000px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: 500px) {
        grid-template-columns: 1fr;
      }

      .loading_data {
        grid-column: 1 / -1;
        display: flex;
        .SalePointContainer {
        padding: 0;

        .mainContentSalePoint {
          .header {
            max-width: 100%;
            background-color: #FFFFFF;
            display: flex;
            gap: 20px;
            align-items: center;
            padding: 14px 38px 14px 38px;

            &__title {
              width: 55px;
              font-size: 28px;
              line-height: 44px;
              font-weight: 600;
            }

            .dropdown {
              width: 210px;
              background-color: #F6F9FC;
              padding: 12px;
              border-radius: 12px;
              line-height: 20px;
              font-weight: 600;
              font-size: 16px;
              text-align: left;
              color: $primary-color;
              display: flex;
              align-items: center;
              gap: 10px;

              &__location {
                font-size: 16px;
                line-height: 20px;
                font-weight: 600;
                color: $primary-color;
              }
            }
          }

          .list-item {
            padding: 40px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 32px;

            .loading_data {
              grid-column: 1 / -1;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .no_content {
              grid-column: 1 / -1;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .list-pos-widget {
              border-radius: 12px;
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
              padding: 26px 20px 26px 20px;
              background-color: #fff;

              .card-body {
                padding: 0;

                .list-unstyled {
                  display: flex;
                  flex-direction: column;
                  gap: 12px;
                }

                .three-dots-button {
                  width: 28px;
                  height: 28px;
                  border-radius: 50%;
                  background-color: #6D727C;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                  border: none;
                  color: #ffffff;
                  position: relative;
                  .three-dots-button i {
                    position: relative;
                    color: #FFFFFF;
                    font-size: 16px;
                  }
                }


                .card-title {
                  margin: 0;
                  font-size: 24px;
                  font-weight: 600;
                }

                .btn-link {
                  font-size: 1.5rem;
                  color: #6c757d;
                  text-decoration: none;
                }

                .btn-primary {
                  background-color: $primary-color;
                  border-color: $primary-color;
                  border-radius: 100px;
                  padding: 12px 0;
                  color: #ffff;
                  font-size: 16px;
                  font-weight: 500;
                }

                .btn-warning {
                  background-color: #F97414;
                  border-color: #F97414;
                  border-radius: 100px;
                  padding: 12px 0;
                  color: #ffff;
                  font-size: 16px;
                  font-weight: 500;
                }


                .user-info {
                  display: flex;
                  align-items: center;
                  gap: 10px;

                  .user-avatar {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    cursor: pointer;
                  }

                  .user-name {
                    display: none;
                    position: absolute;
                    background-color: #fff;
                    padding: 5px 10px;
                    border-radius: 5px;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                    white-space: nowrap;
                    margin-top: 5px;
                  }

                  &:hover .user-name {
                    display: block;
                  }
                }
              }
            }
          }
        }

      }

        .modal-form {
          .line {
            height: 1px;
            background-color: #E9F4FF;
          }

          .modal-body {
            padding: 20px;

            .form-group {
              margin-bottom: 20px;

              label {
                display: block;
                margin-bottom: 8px;
                font-size: 14px;
                font-weight: bold;
                color: #2f2f2f;
              }

              .input-group {
                display: flex;
                align-items: center;

                .form-control {
                  flex: 1;
                  max-width: 50%;
                  border: 1px solid #e0e0e0;
                  border-radius: 8px;
                  background-color: #f9f9f9;
                  padding: 10px;
                }

                .input-group-append {
                  .input-group-text {
                    border: 1px solid $primary-color;
                    border-radius: 8px;
                    background-color: #ffffff;
                    padding: 0;
                    margin-left: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 48px;
                    height: 48px;
                  }

                  img {
                    max-width: 24px;
                  }
                }
              }

              textarea {
                border: 1px solid #e0e0e0;
                border-radius: 8px;
                background-color: #f9f9f9;
                width: 100%;
                padding: 10px;
                resize: none;
              }
            }
          }

          .modal-footer {
            padding: 20px;
            border-top: none;
            text-align: center;

            .btn-open-session {
              background-color: $primary-color;
              border: none;
              border-radius: 25px;
              padding: 10px 20px;
              color: #ffffff;
              font-weight: bold;
              width: 300px;
            }
          }
        }

        .line {
          height: 1px;
          background-color: #E9F4FF;
          margin-bottom: 20px;
        }

        .currency-table {
          display: flex;
          justify-content: space-between;

          .currency-column {
            width: 48%;
            padding: 20px;
          }

          .currency-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px;
            background-color: #FFFFFF;
            padding: 5px;
            border-radius: 4px;
            height: 52px;

            .currency-operation {
              display: flex;
              align-items: center;
              background-color: #E9F4FF;
              border-radius: 2px;
            }

            .currency-input {
              width: 84px;
              height: 48px;
              text-align: center;
              border: none;
              background-color: #FFFFFF;
            }

            .currency-value {
              width: 100px;
              text-align: left;
              font-size: 16px;
              font-weight: 400;
            }
            .btn-light{
              border:none;
              outline: none;
            }
            .btn {
              width: 52px;
              height: 52px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: none;
              background-color: #E9F4FF;
              font-size: 18px;
              cursor: pointer;
              outline: none;
            }
          }
        }

        .footer {
          display: flex;
          align-items: center;
          margin-top: 20px;

          .btn-primary {
            width: 300px;
            background-color: $primary-color;
            border-color: $primary-color;
            border-radius: 100px;
            padding: 12px 0;
            color: #fff;
            font-size: 16px;
            font-weight: 500;
          }

          .total {
            font-weight: bold;
            font-size: 20px;
            margin-left: 10px;
          }
        }

        .selectPopoverPos {
          width: 185px ;
          padding-top: 6px;
          padding-bottom: 6px;
          p{
            color: #061020;
            font-weight: 400;
            line-height: 19.36px;
            letter-spacing: -0.0299999993px;
            border-bottom: 1px solid #ECF2F7;
            padding-bottom: 6px;
            font-size: 16px;
            cursor: pointer;
            &:hover{
              font-weight: 500;
            }
          }
          p:last-child{
            margin-bottom: 0;
            border-bottom: unset;
            padding-bottom: 0;
          }
        }
        align-items: center;
        justify-content: center;
      }

      .no_content {
        grid-column: 1 / -1;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .list-pos-widget {
        border-radius: 12px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        padding: 26px 20px 26px 20px;
        background-color: #fff;

        .card-body {
          padding: 0;

          .list-unstyled {
            display: flex;
            flex-direction: column;
            gap: 12px;
          }

          .three-dots-button {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background-color: #6D727C;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border: none;
            color: #ffffff;
            position: relative;
            .three-dots-button i {
              position: relative;
              color: #FFFFFF;
              font-size: 16px;
            }
          }


          .card-title {
            margin: 0;
            font-size: 24px;
            font-weight: 600;
          }

          .btn-link {
            font-size: 1.5rem;
            color: #6c757d;
            text-decoration: none;
          }

          .btn-primary {
            background-color: $primary-color;
            border-color: $primary-color;
            border-radius: 100px;
            padding: 12px 0;
            color: #ffff;
            font-size: 16px;
            font-weight: 500;
          }

          .btn-warning {
            background-color: #F97414;
            border-color: #F97414;
            border-radius: 100px;
            padding: 12px 0;
            color: #ffff;
            font-size: 16px;
            font-weight: 500;
          }


          .user-info {
            display: flex;
            align-items: center;
            gap: 10px;

            .user-avatar {
              width: 24px;
              height: 24px;
              border-radius: 50%;
              cursor: pointer;
            }

            .user-name {
              display: none;
              position: absolute;
              background-color: #fff;
              padding: 5px 10px;
              border-radius: 5px;
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
              white-space: nowrap;
              margin-top: 5px;
            }

            &:hover .user-name {
              display: block;
            }
          }
        }
      }
    }
  }

}

.modal-form {
  .line {
    height: 1px;
    background-color: #E9F4FF;
  }

  .modal-body {
    padding: 20px;

    .form-group {
      margin-bottom: 20px;

      label {
        display: block;
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: bold;
        color: #2f2f2f;
      }

      .input-group {
        display: flex;
        align-items: center;

        .form-control {
          flex: 1;
          max-width: 50%;
          border: 1px solid #e0e0e0;
          border-radius: 8px;
          background-color: #f9f9f9;
          padding: 10px;
        }

        .input-group-append {
          .input-group-text {
            border: 1px solid $primary-color;
            border-radius: 8px;
            background-color: #ffffff;
            padding: 0;
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 48px;
          }

          img {
            max-width: 24px;
          }
        }
      }

      textarea {
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        background-color: #f9f9f9;
        width: 100%;
        padding: 10px;
        resize: none;
      }
    }
  }

  .modal-footer {
    padding: 20px;
    border-top: none;
    text-align: center;

    .btn-open-session {
      background-color: $primary-color;
      border: none;
      border-radius: 25px;
      padding: 10px 20px;
      color: #ffffff;
      font-weight: bold;
      width: 300px;
    }
  }
}

.line {
  height: 1px;
  background-color: #E9F4FF;
  margin-bottom: 20px;
}

.currency-table {
  display: flex;
  justify-content: space-between;

  .currency-column {
    width: 48%;
    padding: 20px;
  }

  .currency-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    background-color: #FFFFFF;
    padding: 5px;
    border-radius: 4px;
    height: 52px;

    .currency-operation {
      display: flex;
      align-items: center;
      background-color: #E9F4FF;
      border-radius: 2px;
    }

    .currency-input {
      width: 84px;
      height: 48px;
      text-align: center;
      border: none;
      background-color: #FFFFFF;
    }

    .currency-value {
      width: 100px;
      text-align: left;
      font-size: 16px;
      font-weight: 400;
    }
    .btn-light{
      border:none;
      outline: none;
    }
    .btn {
      width: 52px;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: #E9F4FF;
      font-size: 18px;
      cursor: pointer;
      outline: none;
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  margin-top: 20px;

  .btn-primary {
    width: 300px;
    background-color: $primary-color;
    border-color: $primary-color;
    border-radius: 100px;
    padding: 12px 0;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
  }

  .total {
    font-weight: bold;
    font-size: 20px;
    margin-left: 10px;
  }
}

.selectPopoverPos {
  width: 185px ;
  padding-top: 6px;
  padding-bottom: 6px;
  p{
    color: #061020;
    font-weight: 400;
    line-height: 19.36px;
    letter-spacing: -0.0299999993px;
    border-bottom: 1px solid #ECF2F7;
    padding-bottom: 6px;
    font-size: 16px;
    cursor: pointer;
    &:hover{
      font-weight: 500;
    }
  }
  p:last-child{
    margin-bottom: 0;
    border-bottom: unset;
    padding-bottom: 0;
  }
}
.modalMoneyControl{
  &.disable{
    pointer-events: none;
  }
}