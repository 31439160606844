.mainContentProduct {
    padding-top: 20px;
    display: flex;
    gap: 12px;
    height: calc(100vh - 85.58px);
    .filter {
        width: 232px;
        h1 {
            font-size: 20px;
            font-weight: 600;
            line-height: 24.2px;
            letter-spacing: -0.02em;
            text-align: left;
            margin-bottom: 0px;
            padding: 10px 0px;
        }
        .filter-list {
            display: grid;
            gap: 12px;
            height: calc(100% - 44.2px);
            overflow-y: scroll;
        }
        .filter-goods-manager {
            background: #ffffff;
            border-radius: 8px;
            .btn-primary {
                width: 100%;
                background: unset;
                font-size: 14px;
                font-weight: 600;
                line-height: 19.6px;
                letter-spacing: -0.20999999344348907px;
                text-align: left;
                color: #242133;
                border: unset;
                padding: 10px 12px;
                &:focus {
                    box-shadow: unset;
                }
            }
            .collapse {
                list-style-type: none;
                padding-left: 0px;
                .radio-buttons {
                    display: flex;
                }

                .radio-button {
                    display: flex;
                    align-items: center;
                    margin-right: 10px;
                    position: relative;
                    padding: 12px 10px;
                    cursor: pointer;
                    width: 100%;
                    transition: 0.3s;
                    &:hover .radio-tick{
                      transition: 0.3s;
                      border: 1px solid #0E72ED;
                    }
                    p {
                        width: calc(100% - 16px);
                        margin-bottom: 0px;
                    }
                }

                .radio-button input[type="checkbox"] {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                }

                .radio-tick {
                    width: 16px;
                    height: 16px;
                    border: 1px solid rgb(217,217,217);
                    margin-right: 10px;
                    border-radius: 4px;
                }

                .radio-button
                    input[type="checkbox"]:checked
                    + .radio-tick::before {
                    content: "\f00c";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    border: unset;
                    font-size: 10px;
                    height: 15px;
                }

                .radio-button input[type="checkbox"]:checked ~ .radio-tick {
                    background-color: #0e72ed;
                    border: 1.5px solid #0e72ed;
                }
            }
            .collapsing {
                list-style-type: none;
                padding-left: 0px;
                .radio-buttons {
                    display: flex;
                }

                .radio-button {
                    display: flex;
                    align-items: center;
                    margin-right: 10px;
                    position: relative;
                    padding: 12px 10px;
                    cursor: pointer;
                    width: 100%;
                }

                .radio-button input[type="checkbox"] {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                }

                .radio-tick {
                    width: 16px;
                    height: 16px;
                    border: 1.5px solid #6f767e;
                    border-radius: 50%;
                    margin-right: 10px;
                }

                .radio-button
                    input[type="checkbox"]:checked
                    + .radio-tick::before {
                    content: "\f00c";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    border: unset;
                    font-size: 10px;
                    height: 15px;
                }

                .radio-button input[type="checkbox"]:checked ~ .radio-tick {
                    background-color: #0e72ed;
                    border: 1.5px solid #0e72ed;
                    border-radius: 50%;
                }
            }
        }
    }
    .content-page {
        height: 100%;
        width: calc(100% - 232px);
        .content-page-header {
            display: grid;
            grid-template-columns: 1fr 1.4fr;
            .content-page-header-left {
                display: grid;
                gap: 12px;
                grid-template-columns: 1fr;
                .content-page-header-search {
                    border-radius: 8px;
                    background: #fff;
                    display: flex;
                    padding: 4px;
                    input {
                        width: calc(100% - 36px);
                        border: unset;
                        border: unset;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 19.6px;
                        letter-spacing: -0.2800000011920929px;
                        text-align: left;
                        color: #061020;
                        &:focus {
                            outline: unset;
                        }
                    }
                    button {
                        width: 36px;
                        background: #0e72ed;
                        height: 36px;
                        border-radius: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: unset;
                    }
                }
                //.content-page-header-sorting{
                //  display: grid;
                //  align-items: center;
                //  button{
                //    width: 36px;
                //    height: 36px;
                //    background: #BBD9FF;
                //    border-radius: 6.55px;
                //    display: grid;
                //    align-items: center;
                //    justify-content: center;
                //    border: unset;
                //  }
                //}
            }
            .content-page-header-right {
                display: flex;
                gap: 12px;
                justify-content: end;
                .content-page-header-right-plus {
                    background: #0e72ed;
                    border-radius: 8px;
                    width: auto;
                    display: grid;
                    align-items: center;
                    border: unset;
                    justify-content: center;
                    padding: 0px 16px 0px 12px;
                    span {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 16.94px;
                        letter-spacing: -0.2800000011920929px;
                        text-align: left;
                        color: #ffffff;
                        position: relative;
                        padding-left: 26px;
                        img {
                            position: absolute;
                            top: -4px;
                            left: 0px;
                        }
                    }
                }
                .content-page-header-right-import {
                    width: auto;
                    display: grid;
                    align-items: center;
                    border: 1px solid #0e72ed;
                    justify-content: center;
                    background: #0e72ed;
                    background: unset;
                    padding: 0px 16px 0px 12px;
                    border-radius: 8px;
                    span {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 16.94px;
                        letter-spacing: -0.2800000011920929px;
                        text-align: left;
                        color: #0e72ed;
                        position: relative;
                        padding-left: 26px;
                        img {
                            position: absolute;
                            top: -4px;
                            left: 0px;
                        }
                    }
                }
                .content-page-header-right-export {
                    width: auto;
                    display: grid;
                    align-items: center;
                    border: 1px solid #0e72ed;
                    justify-content: center;
                    background: #0e72ed;
                    background: unset;
                    padding: 0px 16px 0px 12px;
                    border-radius: 8px;
                    span {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 16.94px;
                        letter-spacing: -0.2800000011920929px;
                        text-align: left;
                        color: #0e72ed;
                        position: relative;
                        padding-left: 26px;
                        img {
                            position: absolute;
                            top: -4px;
                            left: 0px;
                        }
                    }
                }
                .menuFile {
                    min-width: 210px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 8px 12px;
                    border-radius: 8px;
                    background-color: white;
                    border: 1px solid #0e72ed;
                    position: relative;
                    cursor: pointer;
                    color: #0e72ed;
                    .menuFile-header {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 12px;
                    }
                    .menuFile-list {
                        list-style-type: none;
                        padding-left: 0;
                        margin-bottom: 0;
                        position: absolute;
                        border-radius: 8px;
                        background-color: white;
                        width: 100%;
                        top: 120%;
                        left: 0;
                        padding: 4px 0;
                        transition: 0.2s ease-in-out;
                        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                        border: 1px solid #ccc;
                        .item {
                            padding: 8px 12px;
                            display: flex;
                            justify-content: start;
                            align-items: center;
                            span,
                            button {
                                outline: none;
                                border: none;
                                background-color: transparent;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                gap: 12px;
                                color: #0e72ed;
                                padding: 0;
                                a {
                                    text-decoration: none;
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    gap: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .content-table {
            padding-top: 12px;
            height: auto;
            .table-responsive {
                height: 100%;
                overflow: scroll;
                table {
                    height: 100%;
                }
            }
            .table-responsive thead tr th {
                padding: 12px 10px;
            }
        }
    }
}
.modalLoadingImportFile {
    max-width: 400px;
    // height: 350px;
    border-radius: 3px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-modal-close {
        display: none;
    }
    .ant-modal .ant-modal-content {
        border-radius: 4px;
        background-color: none;
        box-shadow: none !important;
    }
    .ant-modal-content {
        padding: 34px 12px;
        box-shadow: none;
    }
    .ant-modal-body {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        .title {
            margin-bottom: 0;
            font-size: 24px;
            font-weight: 600;
            text-align: center;
        }
    }
}
.modalImportFile {
    .ant-modal-content {
        padding: 12px 16px;
    }
    .importFile-title {
        margin-bottom: 8px;
        span {
            font-weight: 800;
            color: #6c757d;
        }
    }
    .importFile-input {
        background: #ecf2f7;
        padding: 32px 12px 40px 12px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        height: 200px;
        // span {
        //   color: #6c757d;
        //   font-size: 14px;
        // }
        button {
            font-weight: 700;
            padding: 10px 32px;
            min-width: 300px;
            border: none;
            outline: none;
            background-color: #0e72ed;
            border-radius: 100px;
            color: #fff;
            margin-top: 12px;
        }
        .title {
            margin-top: 12px;
            font-size: 22px;
            font-weight: 600;
        }
        .file {
            gap: 20px;
            padding: 12px 0;
            .uploadFile {
                font-weight: 700;
                padding: 10px 32px;
                min-width: 100px;
                border: none;
                outline: none;
                background-color: #0e72ed;
                border-radius: 100px;
                color: #fff;
                position: relative;
            }
        }
    }
    .importFile-errorLog {
        margin-top: 28px;
        p,
        span {
            font-size: 16px;
        }
    }
    .importFile-table {
        display: flex;
        flex-direction: column;
        margin-top: 28px;
        padding: 18px 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        .table-header {
            line-height: 19.36px;
            font-size: 16px;
            color: #000;
            font-weight: 600;
        }
        .table-body {
            overflow: auto;
            height: 400px;
            .table-responsive {
                padding: 0;
                thead {
                    tr {
                        th {
                            align-content: center;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 992px) {
    .mainContentProduct .content-page .content-page-header {
        grid-template-columns: 1fr 1fr;
    }
}
