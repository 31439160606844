
.hidden-barcode{
    display: none;
}
#html5-qrcode-button-camera-stop{
    display: none!important;
}
#html5-qrcode-button-camera-stop{
    display: none!important;
}
#html5-qrcode-anchor-scan-type-change {
    display: none !important;
}
#html5qr-code-full-region{
    width: calc(100% - 520px);
    height: 100%;
    #html5qr-code-full-region__scan_region{
        height: 100%;
        video{
            width: 100%;
            height: 100% !important;
        }
        img{
            display: none!important;
        }
    }
}
#html5-qrcode-button-camera-permission{
    background: #0e72ed;
    padding: 10px 20px;
    border-radius: 16px;
    font-size: 24px;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: -1.4400000572px;
    text-align: left;
    color: #FFFFFF;
    border: unset;
}
.ProductInPosPage-container{
    height: calc(100vh - 165px); /* Chiếm toàn bộ chiều cao màn hình */
    display: flex;
    flex-direction: column;
    .ProductInPosPage-body{
        height: 100%;
        height: calc(100vh - 165px);
        display: flex;
        //grid-template-columns: 2fr 1fr;
        //flex: 1;
        .listProduct{
            width: calc(100% - 520px);
            padding: 20px 32px 20px 32px;
            //grid-column: span 2 / span 2;
            height: calc(100vh - 165px);
            overflow-y: auto;
            &-box{
                display: grid;
                grid-template-columns: repeat(6, minmax(0, 1fr));
                gap: 12px;

                @media (min-width: 768px) and (max-width: 1280px) {
                    display: grid;
                    grid-template-columns: repeat(3, minmax(0, 1fr));
                    gap: 12px;
                }
            }
            .product-item{
                cursor: pointer;
                background-color: #FFF;
                border-radius: 8px;
                border: 1px solid #fff;
                height: 180px;
                &__body{
                    padding: 8px 0 8px 8px;
                }
                &__boxImg{
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                    height: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #f4f3f2;
                    img{
                        max-height: 100%;
                        max-width: 100%;
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;
                        padding: 2px;
                    }
                }
                h2{
                    color: #061020;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19.6px;
                    letter-spacing: -0.28px;
                    margin-bottom: 4px;
                    max-width: 120px;

                    &.multiline-ellipsis {
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2; /* start showing ellipsis when 2rd line is reached */
                        white-space: pre-wrap; /* let the text wrap preserving spaces */
                      }
                }
                h3{
                    color: #061020;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 19.6px;
                    letter-spacing: -0.21px;
                    margin-bottom: 0;
                }   
            }
        }
    }
}
@media screen and (max-width:992px){
    .ProductInPosPage-container .ProductInPosPage-body .listProduct{
        padding: 12px;
        .product-item {
            h2{
                font-size: 12px;
            }
            h3{
                font-size: 12px;
            }
        }
    }
}
@media screen and (max-width:900px){
    .ProductInPosPage-container .ProductInPosPage-body {
        .listProduct{
            width: calc(100% - 420px);
            .listProduct-box{
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }
        }
        .ProductInPosControl{
            width: 420px;
        }
    }
    #html5qr-code-full-region{
        width: calc(100% - 420px);
    }
}